/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import CreateIcon from "@material-ui/icons/Create";
import BlurOn from "@material-ui/icons/BlurOn";
import BlurOff from "@material-ui/icons/BlurOff";
import CheckIcon from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import {
    Button,
    Checkbox,
    FilledInput,
    FormControl,
    FormControlLabel,
    IconButton,
    Paper,
    Select
} from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import MenuItem from "@material-ui/core/MenuItem";
import InputMask from "react-input-mask";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import PlacesAutocomplete from "react-places-autocomplete";
import { useStyles } from "../Common/_styles/detailsFormStyles";
import { CropperDialog } from "../../modules/Common/CropperDialog";
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from "@material-ui/pickers";
import { he, enUS } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { actions as snackbarActions } from "../../../redux/snackbar/snackbarRedux";
import SupplierItemSelectorDialog from "./Duplication/SupplierItemSelectorDialog";
import { useDispatch } from "react-redux";
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers";
import DeleteIcon from "@material-ui/icons/Delete";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Cropper from "react-cropper";
import StateFreezeFormDialog from "./StateFreezeFormDialog";
import "cropperjs/dist/cropper.css";
import { getSuppliers } from "../../modules/Suppliers/_axios/supplierCrud";
import axios from "axios";

function Thumb({ imageBase64, initialURL, setStoredImageURL }) {
    const [state, setState] = useState({
        loading: false
    });

    useEffect(() => {
        if (imageBase64) {
            setState({ loading: true });
            setStoredImageURL(imageBase64);
            setState({ loading: false, thumb: imageBase64 });
        } else {
            setState({ loading: false, thumb: initialURL });
            setState({ loading: false, thumb: initialURL });
        }
    }, [initialURL, imageBase64]);

    if (!initialURL && !imageBase64) {
        return null;
    }

    if (state.loading) {
        return <span className="spinner spinner-white"></span>;
    }
    return (
        <Avatar
            alt="Logo"
            variant="rounded"
            src={state.thumb}
            style={{ height: 100, width: 100 }}
        />
    );
}

function SupplierDetails({
    intl,
    selectedSupplier,
    allSuppliers,
    submitSupplier,
    submitSupplierCopyItem,
    submitCertificateDoc,
    updateSuppliers,
    isUserOwner,
    saveImage,
    settingsFreezeSupplier,
    driversList
}) {
    const locale = intl.locale === "en" ? enUS : he;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [loading, setLoading] = useState(true);

    const [storedImageURL, setStoredImageURL] = useState("");
    const [cropData, setCropData] = useState("#");
    const [showCropper, setShowCropper] = useState(false);
    const [showStatePopup, setShowStatePopup] = useState(false);
    useEffect(() => {
        function fetchSuppliers(cancelToken) {
            setSupplierData({ ...supplierData, isLoading: true });
            getSuppliers(cancelToken.token, "active")
                .then(({ data }) => {
                    setSupplierData({
                        ...supplierData, data, isLoading: false
                    });
                })
                .catch(error => handleApiError(dispatch, error, intl.formatMessage({
                    id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                })));
        }

        const cancelToken = axios.CancelToken.source();
        fetchSuppliers(cancelToken);
        return () => cancelToken.cancel();
    }, []);

    const [supplierData, setSupplierData] = useState({
        data: [], isLoading: true, backup_data: [],
    });


    const [image, setImage] = useState("");
    const onChange = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };
    const orderTimeTypes = [
        {
            name: "Day before",
            value: 1
        },
        {
            name: "The same day",
            value: 0
        }
    ];
    const posTypes = [
        {
            name: "None",
            value: 0
        },
        {
            name: "Betavon",
            value: 1
        },
        {
            name: "BeeCom",
            value: 2
        }
    ];
    const initialValues = {
        name: selectedSupplier.Name || "",
        posType: +selectedSupplier.PosType || 0,
        merchantId: +selectedSupplier.MerchantId || "",
        managerMobile: selectedSupplier.ManagerMobile || "",
        posBranchID: selectedSupplier.PosBranchID || "",
        formalName: selectedSupplier.FormalName || "",
        phone: selectedSupplier.Phone || "",
        email: selectedSupplier.Email || "",
        companyID: selectedSupplier.CompanyID || "",
        address: selectedSupplier.Address || "",
        geoPoint: selectedSupplier.GeoPoint || {},
        googlePlaceId: selectedSupplier.GooglePlaceId || "",
        maxBreakfast: selectedSupplier.MaxBreakfast || 0,
        maxLunch: selectedSupplier.MaxLuanch || 0,
        maxDinner: selectedSupplier.MaxDinner || 0,
        docLink: selectedSupplier.DocLink || "",
        orderTimeType: +selectedSupplier.OrderTimeType,
        driverId: selectedSupplier.DriverId || "",
        orderTime:
            selectedSupplier.OrderTime && selectedSupplier.OrderTime !== "00:00:00"
                ? new Date(`2020-04-20T${selectedSupplier.OrderTime}`)
                : null,
        reportEmails: selectedSupplier.ReportEmails || "",
        reportDeliveryTime:
            selectedSupplier.ReportDeliveryTime &&
                selectedSupplier.ReportDeliveryTime !== "00:00:00"
                ? new Date(`2020-04-20T${selectedSupplier.ReportDeliveryTime}`)
                : null,
        posOrderDinnerTime:
            selectedSupplier.PosOrderDinnerTime &&
                selectedSupplier.PosOrderDinnerTime !== "00:00:00"
                ? new Date(`2020-04-20T${selectedSupplier.PosOrderDinnerTime}`)
                : null,
        posOrderBreakfastTime:
            selectedSupplier.PosOrderBreakfastTime &&
                selectedSupplier.PosOrderBreakfastTime !== "00:00:00"
                ? new Date(`2020-04-20T${selectedSupplier.PosOrderBreakfastTime}`)
                : null,
        posOrderLunchTime:
            selectedSupplier.PosOrderLunchTime &&
                selectedSupplier.PosOrderLunchTime !== "00:00:00"
                ? new Date(`2020-04-20T${selectedSupplier.PosOrderLunchTime}`)
                : null,
        sertificateExpirationDate: selectedSupplier.SertificateExpirationDate
            ? new Date(selectedSupplier.SertificateExpirationDate)
            : null
    };

    const nameValidationSchema = Yup.string()
        .test(
            "nameIsUnique",
            intl.formatMessage({
                id: "VALIDATION.NAME_USED"
            }),
            value => {
                const supplier = allSuppliers.find(supplier => supplier.Name === value);
                return (
                    supplier === undefined ||
                    supplier.SupplierID === selectedSupplier.SupplierID
                );
            }
        )
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        );

    const emailValidationSchema = Yup.string()
        .email(
            intl.formatMessage({
                id: "VALIDATION.WRONG_EMAIL_FORMAT"
            })
        )
        .min(2, intl.formatMessage({ id: "VALIDATION.MIN_LENGTH_FIELD" }))
        .max(50, intl.formatMessage({ id: "VALIDATION.MAX_LENGTH_FIELD" }))
        .when("phone", {
            is: phone => {
                return !phone;
            },
            then: Yup.string().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        });

    const reportEmailsValidationSchema = Yup.string().matches(
        /^(([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})(,|$)){1,}$/i,
        intl.formatMessage({
            id: "VALIDATION.WRONG_EMAIL_FORMAT"
        })
    );

    const phoneValidationSchema = Yup.string()
        .matches(
            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
            intl.formatMessage({
                id: "VALIDATION.WRONG_PHONE_FORMAT"
            })
        )
        .when("email", {
            is: email => {
                return !email;
            },
            then: Yup.string().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        });

    const companyValidationSchema = Yup.string().required(
        intl.formatMessage({
            id: "VALIDATION.REQUIRED_FIELD"
        })
    );

    const addressValidationSchema = Yup.string().required(
        intl.formatMessage({
            id: "VALIDATION.REQUIRED_FIELD"
        })
    );

    const maxValidationSchema = Yup.number()
        .integer(intl.formatMessage({ id: "VALIDATION.NOT_INTEGER" }))
        .min(0, intl.formatMessage({ id: "VALIDATION.MIN_VALUE" }))
        .required(
            intl.formatMessage({
                id: "VALIDATION.REQUIRED_FIELD"
            })
        );

    const logoFileValidationSchema = Yup.mixed()
        .when({
            is: () => {
                return !selectedSupplier.Name;
            },
            then: Yup.mixed().required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD"
                })
            )
        })
        .test(
            "fileType",
            intl.formatMessage({
                id: "VALIDATION.UNSUPPORTED_FILE_FORMAT"
            }),
            value => {
                if (value) {
                    return ["image/png", "image/jpeg", "image/jpg"].includes(value.type);
                }
                return true;
            }
        );

    const validationSchema = Yup.object().shape(
        {
            name: nameValidationSchema,
            phone: phoneValidationSchema,
            email: emailValidationSchema,
            companyID: companyValidationSchema,
            address: addressValidationSchema,
            maxBreakfast: maxValidationSchema,
            maxLunch: maxValidationSchema,
            maxDinner: maxValidationSchema,
            formalName: nameValidationSchema,
            reportEmails: reportEmailsValidationSchema,
            logoFile: logoFileValidationSchema
        },
        [
            "phone",
            "email",
            "name",
            "companyID",
            "address",
            "maxBreakfast",
            "maxLunch",
            "maxDinner",
            "formalName",
            "reportEmails",
            "logoFile"
        ]
    );

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            const {
                logoFile,
                name,
                phone,
                email,
                companyID,
                address,
                posType,
                merchantId,
                managerMobile,
                posBranchID,
                geoPoint,
                googlePlaceId,
                maxBreakfast,
                maxLunch,
                maxDinner,
                formalName,
                certificateFile,
                orderTime,
                orderTimeType,
                reportEmails,
                reportDeliveryTime,
                posOrderDinnerTime,
                posOrderLunchTime,
                posOrderBreakfastTime,
                sertificateExpirationDate,
                driverId
            } = values;
            let changedSupplier = {
                SupplierID: selectedSupplier.SupplierID,
                Name: name,
                Phone: phone,
                Email: email,
                CompanyID: companyID,
                Address: address,
                PosType: posType,
                MerchantId: merchantId,
                ManagerMobile: managerMobile,
                PosBranchID: posBranchID,
                GeoPoint: geoPoint,
                GooglePlaceId: googlePlaceId,
                MaxBreakfast: maxBreakfast,
                MaxLuanch: maxLunch,
                MaxDinner: maxDinner,
                FormalName: formalName,
                OrderTimeType: +orderTimeType,
                DriverId: driverId,
                OrderTime: orderTime
                    ? moment(orderTime).format("HH:mm:ss")
                    : "00:00:00",
                ReportEmails: reportEmails,
                ReportDeliveryTime: reportDeliveryTime
                    ? moment(reportDeliveryTime).format("HH:mm:ss")
                    : "00:00:00",
                PosOrderDinnerTime: posOrderDinnerTime
                    ? moment(posOrderDinnerTime).format("HH:mm:ss")
                    : "00:00:00",
                PosOrderLunchTime: posOrderLunchTime
                    ? moment(posOrderLunchTime).format("HH:mm:ss")
                    : "00:00:00",
                PosOrderBreakfastTime: posOrderBreakfastTime
                    ? moment(posOrderBreakfastTime).format("HH:mm:ss")
                    : "00:00:00",
                SertificateExpirationDate: sertificateExpirationDate
                    ? moment(sertificateExpirationDate).format("YYYY-MM-DDTHH:mm:ss")
                    : null
            };
            if (!certificateFile) {
                changedSupplier.DocLink = selectedSupplier.DocLink;
            }
            submitSupplier(changedSupplier)
                .then(() => {
                    if (certificateFile) {
                        submitCertificateDoc(certificateFile)
                            .then(({ data }) => {
                                changedSupplier.DocLink = data;
                                updateSuppliers(changedSupplier);
                                if (logoFile) {
                                    saveImage(changedSupplier.SupplierID, logoFile)
                                        .then(data => {
                                            changedSupplier.Logo = data;
                                            updateSuppliers(changedSupplier);
                                            setSubmitting(false);
                                            setIsReadOnly(true);
                                            dispatch(
                                                snackbarActions.setSnackbarData(
                                                    intl.formatMessage({
                                                        id: "API.EDIT_SUCCESS"
                                                    })
                                                )
                                            );
                                        })
                                        .catch(error => {
                                            setSubmitting(false);
                                            handleApiError(
                                                dispatch,
                                                error,
                                                intl.formatMessage({
                                                    id: "API.ERROR.FAILED_TO_SAVE_IMAGE"
                                                })
                                            );
                                        });
                                } else {
                                    setSubmitting(false);
                                    setIsReadOnly(true);
                                    dispatch(
                                        snackbarActions.setSnackbarData(
                                            intl.formatMessage({
                                                id: "API.EDIT_SUCCESS"
                                            })
                                        )
                                    );
                                }
                            })
                            .catch(error => {
                                handleApiError(
                                    dispatch,
                                    error,
                                    intl.formatMessage({
                                        id: "API.ERROR.FAILED_TO_SAVE_CERTIFICATE"
                                    })
                                );
                                setSubmitting(false);
                            });
                    } else {
                        if (logoFile) {
                            saveImage(changedSupplier.SupplierID, logoFile)
                                .then(data => {
                                    changedSupplier.Logo = data;
                                    updateSuppliers(changedSupplier);
                                    setSubmitting(false);
                                    setIsReadOnly(true);
                                    dispatch(
                                        snackbarActions.setSnackbarData(
                                            intl.formatMessage({
                                                id: "API.EDIT_SUCCESS"
                                            })
                                        )
                                    );
                                })
                                .catch(error => {
                                    setSubmitting(false);
                                    handleApiError(
                                        dispatch,
                                        error,
                                        intl.formatMessage({
                                            id: "API.ERROR.FAILED_TO_SAVE_IMAGE"
                                        })
                                    );
                                });
                        } else {
                            updateSuppliers(changedSupplier);
                            setSubmitting(false);
                            setIsReadOnly(true);
                        }
                    }
                })
                .catch(error => {
                    handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: "API.ERROR.FAILED_TO_UPDATE_SUPPLIER_DETAILS"
                        })
                    );
                    setSubmitting(false);
                });
        }
    });

    function handleSelectPlace(value) {
        formik.setFieldValue("address", value);
        geocodeByAddress(value)
            .then(results => {
                const googlePlaceId = results[0].place_id;
                formik.setFieldValue("googlePlaceId", googlePlaceId ?? null);
                return getLatLng(results[0])
            })
            .then(({ lat, lng }) => {
                formik.setFieldValue("geoPoint", { lat: lat, lon: lng })
            });
    }

    function checkForError(fieldName) {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        return false;
    }

    function renderErrors(fieldName) {
        return checkForError(fieldName) ? (
            <span className={classes.error}>{formik.errors[fieldName]}</span>
        ) : null;
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(",")[0].indexOf("base64") >= 0)
            byteString = atob(dataURI.split(",")[1]);
        else byteString = unescape(dataURI.split(",")[1]);

        // separate out the mime component
        var mimeString = dataURI
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {
            type: mimeString,
            filename: "filename." + mimeString.split("/")[1]
        });
    }

    function handleCropperSubmit(img_b64) {
        const file = dataURItoBlob(img_b64);
        formik.setFieldValue("logoFile", file);
        setImage(img_b64);
        setShowCropper(false);
    }
    const [showAddMealItemFormDialog, setShowAddMealItemFormDialog] = useState(
        false
    );

    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                <MuiPickersUtilsProvider utils={DateFnsUtils}
                    locale={locale}>
                    {selectedSupplier.Name ? (
                        <>
                            <div className={classes.settingsContainer}>
                                {isUserOwner && (
                                    <div className={classes.iconRow}>
                                        {isReadOnly ? (
                                            <Tooltip
                                                title={intl.formatMessage({
                                                    id: "SUPPLIER_DETAIL.EDIT.TOOLTIP"
                                                })}
                                            >
                                                <IconButton
                                                    type="button"
                                                    onClick={() => setIsReadOnly(false)}
                                                >
                                                    <CreateIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={intl.formatMessage({
                                                    id: "SUPPLIER_DETAIL.SUBMIT.TOOLTIP"
                                                })}
                                            >
                                                <IconButton
                                                    type="button"
                                                    onClick={() => formik.submitForm()}
                                                >
                                                    <CheckIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </div>
                                )}
                                {isUserOwner && (
                                    <div className={classes.iconRow}>
                                        {!selectedSupplier?.Freezed ? (
                                            <Tooltip
                                                title={intl.formatMessage({
                                                    id: "SUPPLIER_DETAIL.FREEZED.TOOLTIP"
                                                })}
                                            >
                                                <IconButton
                                                    type="button"
                                                    onClick={() => {
                                                        setShowStatePopup(true);
                                                    }}
                                                >
                                                    <BlurOn />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={intl.formatMessage({
                                                    id: "SUPPLIER_DETAIL.UN_FREEZED.TOOLTIP"
                                                })}
                                            >
                                                <IconButton
                                                    type="button"
                                                    onClick={() => {
                                                        setShowStatePopup(true);
                                                    }}
                                                >
                                                    <BlurOff />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="position-relative">
                                <TextField
                                    id="supplier-name"
                                    name="name"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.NAME"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    hiddenLabel
                                    {...formik.getFieldProps("name")}
                                    error={checkForError("name")}
                                />
                                {renderErrors("name")}
                            </div>
                            <div className="position-relative">
                                <TextField
                                    id="supplier-formalName"
                                    name="formalName"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.FORMAL_NAME"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    hiddenLabel
                                    {...formik.getFieldProps("formalName")}
                                    error={checkForError("formalName")}
                                />
                                {renderErrors("formalName")}
                            </div>
                            <div hidden={process.env.REACT_APP_FLAVOR == 'NY'} className="position-relative">
                                <div className="">
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel htmlFor="mealType">
                                            <FormattedMessage id="ORDER_ITEMS.TYPE_FIELD.LABEL" />
                                        </InputLabel>
                                        <Select
                                            {...formik.getFieldProps("posType")}
                                            disabled={isReadOnly}
                                            className={classes.formControlSelect}
                                            input={
                                                <FilledInput
                                                    name="posType"
                                                    inputProps={{
                                                        className: classes.input
                                                    }}
                                                    id="posType-select"
                                                />
                                            }
                                        >
                                            {posTypes.map(type => (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div hidden={process.env.REACT_APP_FLAVOR == 'NY'} className="position-relative">
                                <TextField
                                    id="supplier-merchantId"
                                    name="merchantId"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.MERCHANT_ID"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    hiddenLabel
                                    {...formik.getFieldProps("merchantId")}
                                    error={checkForError("merchantId")}
                                />
                                {renderErrors("merchantId")}
                            </div>
                            <div className="position-relative">
                                <TextField
                                    id="supplier-managerMobile"
                                    name="managerMobile"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.MANAGER_MOBILE"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    hiddenLabel
                                    {...formik.getFieldProps("managerMobile")}
                                    error={checkForError("managerMobile")}
                                />
                                {renderErrors("managerMobile")}
                            </div>
                            <div hidden={process.env.REACT_APP_FLAVOR == 'NY'} className="position-relative">
                                <TextField
                                    id="supplier-posBranchID"
                                    name="posBranchID"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.POST_BRANCH_ID"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    hiddenLabel
                                    {...formik.getFieldProps("posBranchID")}
                                    error={checkForError("posBranchID")}
                                />
                                {renderErrors("posBranchID")}
                            </div>
                            <div className="position-relative">
                                <InputMask
                                    name="phone"
                                    mask="999-9999999"
                                    {...formik.getFieldProps("phone")}
                                >
                                    {() => (
                                        <TextField
                                            id="phone"
                                            label={intl.formatMessage({
                                                id: "SUPPLIER_DETAIL.LABELS.PHONE"
                                            })}
                                            className={classes.textField}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            margin="normal"
                                            fullWidth
                                            error={checkForError("phone")}
                                            inputProps={{
                                                className: classes.textRight
                                            }}
                                            InputProps={{
                                                readOnly: isReadOnly
                                            }}
                                            dir="ltr"
                                        />
                                    )}
                                </InputMask>
                                {renderErrors("phone")}
                            </div>
                            <div className="position-relative">
                                <TextField
                                    id="email"
                                    name="email"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.EMAIL"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    {...formik.getFieldProps("email")}
                                    error={checkForError("email")}
                                    inputProps={{
                                        className: classes.textRight
                                    }}
                                    dir="ltr"
                                />
                                {renderErrors("email")}
                            </div>
                            <div className="position-relative">
                                <TextField
                                    id="company"
                                    name="companyID"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.COMPANY_ID"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    {...formik.getFieldProps("companyID")}
                                    error={checkForError("companyID")}
                                />
                                {renderErrors("companyID")}
                            </div>
                            <div className="position-relative">
                                <TextField
                                    id="max-breakfast"
                                    name="maxBreakfast"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.MAX_BREAKFAST"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    {...formik.getFieldProps("maxBreakfast")}
                                    error={checkForError("maxBreakfast")}
                                />
                                {renderErrors("maxBreakfast")}
                            </div>
                            <div className="position-relative">
                                <TextField
                                    id="max-lunch"
                                    name="maxLunch"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.MAX_LUNCH"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    {...formik.getFieldProps("maxLunch")}
                                    error={checkForError("maxLunch")}
                                />
                                {renderErrors("maxLunch")}
                            </div>
                            <div className="position-relative">
                                <TextField
                                    id="max-dinner"
                                    name="maxDinner"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.MAX_DINNER"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    {...formik.getFieldProps("maxDinner")}
                                    error={checkForError("maxDinner")}
                                />
                                {renderErrors("maxDinner")}
                            </div>
                            <div className="position-relative">
                                <div className="mt-2 mb-2">
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel htmlFor="orderTimeType">
                                            <FormattedMessage id="ORDER_ITEMS.ORDER_TIME_TYPE_FIELD.LABEL" />
                                        </InputLabel>
                                        <Select
                                            {...formik.getFieldProps("orderTimeType")}
                                            disabled={isReadOnly}
                                            className={classes.formControlSelect}
                                            input={
                                                <FilledInput
                                                    name="orderTimeType"
                                                    inputProps={{
                                                        className: classes.input
                                                    }}
                                                    id="orderTimeType-select"
                                                />
                                            }
                                        >
                                            {orderTimeTypes.map(type => (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>


                            <div className="position-relative">
                                <div className="mt-2 mb-2">
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel htmlFor="driverId">
                                            <FormattedMessage id="ORDER_ITEMS.DRIVER.LABEL" />
                                        </InputLabel>
                                        <Select
                                            {...formik.getFieldProps("driverId")}
                                            disabled={isReadOnly}
                                            className={classes.formControlSelect}
                                            input={
                                                <FilledInput
                                                    name="driverId"
                                                    inputProps={{
                                                        className: classes.input
                                                    }}
                                                    id="driverId-select"
                                                />
                                            }
                                        >
                                            {driversList.map(type => (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="position-relative">
                                <TimePicker
                                    autoOk
                                    ampm={false}
                                    value={formik.values.orderTime}
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.ORDER_TIME"
                                    })}
                                    onChange={value => {
                                        formik.setFieldValue("orderTime", value);
                                    }}
                                    clearable
                                    readOnly={isReadOnly}
                                    TextFieldComponent={({
                                        helperText,
                                        InputProps,
                                        ...props
                                    }) => (
                                        <TextField
                                            name="orderTime"
                                            className={classes.textField}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                readOnly: isReadOnly
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            hiddenLabel
                                            {...props}
                                            variant="outlined"
                                            error={checkForError("orderTime")}
                                        />
                                    )}
                                />
                            </div>
                            <div className="position-relative">
                                <TextField
                                    id="report-emails"
                                    name="reportEmails"
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.REPORT_EMAILS"
                                    })}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        className: classes.textRight
                                    }}
                                    hiddenLabel
                                    {...formik.getFieldProps("reportEmails")}
                                    error={checkForError("reportEmails")}
                                />
                                {renderErrors("reportEmails")}
                            </div>
                            <div className="position-relative">
                                <TimePicker
                                    autoOk
                                    ampm={false}
                                    value={formik.values.reportDeliveryTime}
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.REPORT_DELIVERY_TIME"
                                    })}
                                    onChange={value => {
                                        formik.setFieldValue("reportDeliveryTime", value);
                                    }}
                                    clearable
                                    readOnly={isReadOnly}
                                    TextFieldComponent={({
                                        helperText,
                                        InputProps,
                                        ...props
                                    }) => (
                                        <TextField
                                            name="reportDeliveryTime"
                                            className={classes.textField}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                readOnly: isReadOnly
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            hiddenLabel
                                            {...props}
                                            variant="outlined"
                                            error={checkForError("reportDeliveryTime")}
                                        />
                                    )}
                                />
                            </div>
                            <div hidden={process.env.REACT_APP_FLAVOR == 'NY'} className="position-relative">
                                <TimePicker
                                    autoOk
                                    ampm={false}
                                    value={formik.values.posOrderBreakfastTime}
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.POS_ORDER_BREAKFAST_TIME"
                                    })}
                                    onChange={value => {
                                        formik.setFieldValue("posOrderBreakfastTime", value);
                                    }}
                                    clearable
                                    readOnly={isReadOnly}
                                    TextFieldComponent={({
                                        helperText,
                                        InputProps,
                                        ...props
                                    }) => (
                                        <TextField
                                            name="posOrderBreakfastTime"
                                            className={classes.textField}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                readOnly: isReadOnly
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            hiddenLabel
                                            {...props}
                                            variant="outlined"
                                            error={checkForError("posOrderBreakfastTime")}
                                        />
                                    )}
                                />
                            </div>
                            <div hidden={process.env.REACT_APP_FLAVOR == 'NY'} className="position-relative">
                                <TimePicker
                                    autoOk
                                    ampm={false}
                                    value={formik.values.posOrderLunchTime}
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.POS_ORDER_LUNCH_TIME"
                                    })}
                                    onChange={value => {
                                        formik.setFieldValue("posOrderLunchTime", value);
                                    }}
                                    clearable
                                    readOnly={isReadOnly}
                                    TextFieldComponent={({
                                        helperText,
                                        InputProps,
                                        ...props
                                    }) => (
                                        <TextField
                                            name="posOrderLunchTime"
                                            className={classes.textField}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                readOnly: isReadOnly
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            hiddenLabel
                                            {...props}
                                            variant="outlined"
                                            error={checkForError("posOrderLunchTime")}
                                        />
                                    )}
                                />
                            </div>
                            <div hidden={process.env.REACT_APP_FLAVOR == 'NY'} className="position-relative">
                                <TimePicker
                                    autoOk
                                    ampm={false}
                                    value={formik.values.posOrderDinnerTime}
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.POS_ORDER_DINNER_TIME"
                                    })}
                                    onChange={value => {
                                        formik.setFieldValue("posOrderDinnerTime", value);
                                    }}
                                    clearable
                                    readOnly={isReadOnly}
                                    TextFieldComponent={({
                                        helperText,
                                        InputProps,
                                        ...props
                                    }) => (
                                        <TextField
                                            name="posOrderDinnerTime"
                                            className={classes.textField}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                readOnly: isReadOnly
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            hiddenLabel
                                            {...props}
                                            variant="outlined"
                                            error={checkForError("posOrderDinnerTime")}
                                        />
                                    )}
                                />
                            </div>
                            <PlacesAutocomplete
                                value={formik.values.address}
                                onChange={value => formik.setFieldValue("address", value)}
                                onSelect={handleSelectPlace}
                                searchOptions={{ country: "il", language: "iw" }}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading
                                }) => (
                                    <div className="position-relative">
                                        <TextField
                                            id="address"
                                            name="address"
                                            label={intl.formatMessage({
                                                id: "SUPPLIER_DETAIL.LABELS.ADDRESS"
                                            })}
                                            className={classes.textField}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                readOnly: isReadOnly,
                                                ...getInputProps()
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            error={checkForError("address")}
                                        />
                                        <Paper className={classes.paper} square>
                                            {loading && (
                                                <div className="spinner spinner-blue mt-3"></div>
                                            )}
                                            {suggestions.map(suggestion => {
                                                return (
                                                    <MenuItem
                                                        component="div"
                                                        {...getSuggestionItemProps(suggestion)}
                                                        key={suggestion.placeId}
                                                    >
                                                        {suggestion.description}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Paper>
                                        {renderErrors("address")}
                                    </div>
                                )}
                            </PlacesAutocomplete>

                            <div className="position-relative">
                                <DatePicker
                                    autoOk
                                    ampm={false}
                                    value={formik.values.posOrderDinnerTime}
                                    label={intl.formatMessage({
                                        id: "SUPPLIER_DETAIL.LABELS.CERTIFICATE_EXPIRATION_DATE"
                                    })}
                                    onChange={value => {
                                        formik.setFieldValue("sertificateExpirationDate", new Date(value));
                                    }}
                                    clearable
                                    readOnly={isReadOnly}
                                    TextFieldComponent={({
                                        helperText,
                                        InputProps,
                                        ...props
                                    }) => (
                                        <TextField
                                            name="sertificateExpirationDate"
                                            className={classes.textField}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                readOnly: isReadOnly
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            hiddenLabel
                                            {...props}
                                            value={formik.values.sertificateExpirationDate
                                                ? moment(formik.values.sertificateExpirationDate).format("YYYY-MM-DD")
                                                : ""}
                                            variant="outlined"
                                            error={checkForError("sertificateExpirationDate")}
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.certificateButtonsRow}>
                                {selectedSupplier.DocLink && (
                                    <a
                                        href={selectedSupplier.DocLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={classes.buttonMarginLeft}
                                    >
                                        <Button variant="contained" component="label">
                                            <FormattedMessage id="SUPPLIER_DETAIL.SEE_CERTIFICATE" />
                                        </Button>
                                    </a>
                                )}
                                {!isReadOnly && (
                                    <>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className={classes.buttonMarginLeft}
                                        >
                                            <FormattedMessage id="SUPPLIER_DETAIL.UPLOAD_CERTIFICATE" />
                                            <input
                                                name="certificateFile"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={event => {
                                                    formik.setFieldValue(
                                                        "certificateFile",
                                                        event.currentTarget.files[0]
                                                    );
                                                }}
                                                hidden
                                            />
                                        </Button>
                                        {formik.values.certificateFile &&
                                            formik.values.certificateFile.name}
                                    </>
                                )}
                            </div>
                            <div className={classes.imageButtonsRow}>
                                <div className="col-6">
                                    <div className="row d-flex flex-row mt-2 mb-2">
                                        <div className="col-4 d-flex justify-content-center p-0">
                                            <Thumb
                                                imageBase64={image}
                                                initialURL={
                                                    selectedSupplier.Logo &&
                                                        selectedSupplier.Logo.length !== 0 &&
                                                        selectedSupplier.Logo[0]
                                                        ? selectedSupplier.Logo[0].URL
                                                        : undefined
                                                }
                                                setStoredImageURL={setStoredImageURL}
                                            />
                                        </div>
                                        <div className="col-4 p-0" />
                                    </div>
                                    {!isReadOnly && (
                                        <div className="row mt-2 mb-2">
                                            <div className="col-4 p-0">
                                                <Button variant="contained" component="label">
                                                    <FormattedMessage id="SUPPLIER_DETAIL.UPLOAD_IMAGE" />
                                                    <input
                                                        id="logo-file"
                                                        name="logoFile"
                                                        type="file"
                                                        accept="image/png, image/jpeg, image/jpg"
                                                        disabled={isReadOnly}
                                                        onChange={event => {
                                                            onChange(event);
                                                            setShowCropper(true);
                                                        }}
                                                        hidden
                                                    />
                                                </Button>
                                            </div>
                                            <CropperDialog
                                                show={showCropper}
                                                image={image}
                                                title={<FormattedMessage id="CREATE_FORM.TITLE" />}
                                                onClose={() => setShowCropper(false)}
                                                onSubmit={handleCropperSubmit}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className={classes.form} />
                    )}
                </MuiPickersUtilsProvider>
            </form>
            <StateFreezeFormDialog
                show={showStatePopup}
                closeDialog={() => {
                    setShowStatePopup(false);
                }}
                submitData={settingsFreezeSupplier}
                afterSubmitCloseDialog={(data) => {
                    setShowStatePopup(false);

                    updateSuppliers({
                        ...selectedSupplier,
                        ...data
                    });
                }}
                intl={intl}
                freezed={selectedSupplier?.Freezed}
                message={
                    !selectedSupplier?.Freezed
                        ? intl.formatMessage({
                            id: "ADMIN.CONFIRMATION_FORM.REQUEST_FREEZE"
                        })
                        : intl.formatMessage({
                            id: "ADMIN.CONFIRMATION_FORM.REQUEST_UNFREEZE"
                        })
                }
            />
        </>
    );
}

export default injectIntl(SupplierDetails);
