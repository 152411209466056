/* eslint-disable no-restricted-imports */

import { Button, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { FormattedMessage, injectIntl } from "react-intl"
import OptionGroupPanel from "../modules/Suppliers/OptionGroups/OptionGroupPanel.js"
import SupplierDetails from "../modules/Suppliers/SupplierDetails.js"
import SupplierImportDetails from "../modules/Suppliers/SupplierImportDetails.js"
import ItemPanel from "../modules/Suppliers/Items/ItemPanel.js"
import SpecialDatesPanel from "../modules/Suppliers/SpecialDates/SpecialDatesPanel.js"
import CreateSupplierFormDialog from "../modules/Common/CreateWithNameFormDialog.js"
import { getOptionGroups } from "../modules/Suppliers/_axios/optionGroupCrud"
import {
    getSuppliers,
    getWorkers,
    getSupplier,
    updateSupplier,
    saveCopyItemSupplier,
    settingsFreezeSupplier,
    createSupplier,
    setCertificateDoc,
    saveSupplierLogo
} from "../modules/Suppliers/_axios/supplierCrud"
import SupplierDownshift from "../modules/Suppliers/SupplierDownshift.js"
import MealsPanel from "../modules/Suppliers/Meals/MealsPanel.js"
import { useParams, withRouter } from "react-router"
import OrdersPanel from "../modules/Suppliers/Orders/OrdersPanel.js"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { actions as snackbarActions } from "../../redux/snackbar/snackbarRedux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers.js"
import { useStyles } from "../modules/Common/_styles/editPageStyles"

function SuppliersEditPage({ intl, ...props }) {
    const dispatch = useDispatch()
    const ownSupplierID = useSelector(state => state.auth.user.SupplierID)
    const userRole = useSelector(state => state.auth.user.Role)
    const classes = useStyles()
    let { id: supplierID } = useParams()
    const [suppliersData, setSuppliersData] = useState({
        suppliers: [],
        isLoading: true
    })
    const [selectedSupplier, setSelectedSupplier] = useState({})
    const [driversList, setDriversList] = useState([])
    const [processLoading, setProcessLoading] = useState(false)
    const [optionGroupsData, setOptionGroupsData] = useState({
        optionGroups: [],
        isLoading: true
    })
    const [showCreateSupplierPanel, setShowCreateSupplierPanel] = useState(false)

    const isUserAdmin = userRole === "Admin" || userRole === "Owner"
    const isUserOwner = userRole === "Owner"
    const showAdminTabs = ["Admin", "Owner", "Supplier Admin"].includes(userRole)
    const isUSFormatDate = process.env.REACT_APP_FLAVOR === "NY" && (userRole === "Supplier Admin" || userRole === "Supplier User");

    const [activeTab, setActiveTab] = useState(
        new URLSearchParams(window.location.search).get("tab") ||
        (showAdminTabs ? "orders" : "meals")
    )

    function fetchSuppliers(cancelToken) {
        setSuppliersData({ ...suppliersData, isLoading: true })

        if (isUserAdmin) {
            // Request for Admin
            getSuppliers(cancelToken.token, "active")
                .then(({ data }) => {
                    setSuppliersData({
                        ...suppliersData,
                        suppliers: data,
                        isLoading: false
                    })
                    if (supplierID) {
                        setSelectedSupplier(
                            data.find(supplier => supplier.SupplierID === supplierID) || {}
                        )
                    }
                })
                .catch(error =>
                    handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                        })
                    )
                )
        } else {
            // Request for Supplier Admin
            getSupplier(cancelToken.token, ownSupplierID)
                .then(({ data }) => {
                    setSuppliersData({
                        ...suppliersData,
                        suppliers: [data],
                        isLoading: false
                    })
                    setSelectedSupplier(data)
                })
                .catch(error =>
                    handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: "API.ERROR.FAILED_TO_GET_SUPPLIER"
                        })
                    )
                )
        }
    }

    function fetchDrivers(cancelToken) {
        setDriversList([])

        getWorkers(cancelToken.token)
            .then(({ data }) => {
                const formattedDrivers = data.map((driver, index) => ({
                    name: driver.displayName || driver.name, 
                    value: driver.id
                }));

                setDriversList(formattedDrivers);
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIER"
                    })
                )
            )
    }

    function saveSupplier(submittedSupplier) {
        return updateSupplier(submittedSupplier, submittedSupplier.SupplierID)
    }

    function saveCopyItem(data, submittedSupplier) {
        return saveCopyItemSupplier(data, submittedSupplier.SupplierID)
    }

    function settingsFreezeSupplierSave(settings) {
        return settingsFreezeSupplier(settings, selectedSupplier.SupplierID);
    }

    function saveImage(customerID, imageFile) {
        const data = new FormData()
        data.append("file", imageFile)
        return saveSupplierLogo(data, selectedSupplier.SupplierID)
    }

    function saveCertificateDoc(file) {
        const data = new FormData()
        data.append("file", file)
        return setCertificateDoc(selectedSupplier.SupplierID, data)
    }

    function updateSuppliers(submittedSupplier) {
        const suppliers = [...suppliersData.suppliers]
        const changedSupplier = suppliers.find(
            supplier => supplier.SupplierID === submittedSupplier.SupplierID
        )
        if (changedSupplier !== undefined) {
            Object.assign(changedSupplier, submittedSupplier)

            setSelectedSupplier({ ...changedSupplier })
            setSuppliersData({
                ...suppliersData,
                suppliers: [...suppliers],
                isLoading: false
            })
        } else {
            const newSupplier = {
                Address: null,
                CompanyID: null,
                Email: null,
                GeoPoint: null,
                MaxBreakfast: 0,
                MaxDinner: 0,
                MaxLuanch: 0,
                Name: submittedSupplier.Name,
                Phone: null,
                SupplierID: submittedSupplier.SupplierID
            }
            setSuppliersData({
                ...suppliersData,
                suppliers: [...suppliers, newSupplier]
            })
            setShowCreateSupplierPanel(false)
            setSelectedSupplier({
                ...newSupplier
            })
        }
    }

    function fetchOptionGroups(cancelToken) {
        setOptionGroupsData({ ...optionGroupsData, isLoading: true })
        getOptionGroups(selectedSupplier.SupplierID, cancelToken.token)
            .then(({ data }) => {
                setOptionGroupsData({
                    ...optionGroupsData,
                    optionGroups: data,
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_OPTION_GROUPS"
                    })
                )
            )
    }

    useEffect(() => {
        if (selectedSupplier.SupplierID) {
            const urlParams = new URLSearchParams(window.location.search)
            props.history.push({
                pathname: `/supplier/${selectedSupplier.SupplierID}`,
                search: "?" + urlParams.toString()
            })

            if (showAdminTabs) {
                loadOptionGroups();
            }
        }
    }, [selectedSupplier])

    useEffect(() => {
        if (activeTab === 'items' || activeTab === 'options') {
            loadOptionGroups();
        }
    }, [activeTab])

    function loadOptionGroups() {
        const cancelToken = axios.CancelToken.source()
        fetchOptionGroups(cancelToken)
        return () => cancelToken.cancel()
    }

    useEffect(() => {
        if (userRole === "Supplier User") {
            setSelectedSupplier({ SupplierID: ownSupplierID })
        } else {
            const cancelToken = axios.CancelToken.source()
            fetchSuppliers(cancelToken)
            return () => cancelToken.cancel()
        }
    }, [])

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchDrivers(cancelToken)
    }, [])

    const handleNewSupplierSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        const newSupplier = { Name: values.name }
        createSupplier(newSupplier)
            .then(({ data }) => {
                setSubmitting(false)
                dispatch(
                    snackbarActions.setSnackbarData(
                        intl.formatMessage({
                            id: "API.CREATE_SUCCESS"
                        })
                    )
                )
                updateSuppliers({
                    ...newSupplier,
                    SupplierID: data.Id
                })
                resetForm()
            })
            .catch(error => {
                setSubmitting(false)
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_CREATE_SUPPLIER"
                    })
                )
            })
    }

    return (
        <>
            <div className={classes.headerPinned}>
                <Typography variant="h6" className={classes.title}>
                    <FormattedMessage id="TITLE" />
                </Typography>
                {isUserAdmin && (
                    <>
                        <SupplierDownshift
                            selectedSupplier={selectedSupplier}
                            selectSupplier={setSelectedSupplier}
                            suppliersData={suppliersData}
                        />
                        <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            className={classes.button}
                            onClick={() => setShowCreateSupplierPanel(true)}
                        >
                            <FormattedMessage id="CREATE_NEW_BUTTON" />
                        </Button>
                        <CreateSupplierFormDialog
                            show={showCreateSupplierPanel}
                            title={<FormattedMessage id="CREATE_FORM.TITLE" />}
                            closeDialog={() => setShowCreateSupplierPanel(false)}
                            handleSubmit={handleNewSupplierSubmit}
                            checkIfNameUnique={value => {
                                const supplier = suppliersData.suppliers.find(
                                    supplier => supplier.Name === value
                                )
                                return supplier === undefined
                            }}
                        />
                        <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            className={classes.button}
                            component={Link}
                            to="/suppliers"
                        >
                            <FormattedMessage id="GO_BACK" />
                        </Button>
                    </>
                )}
            </div>
            {selectedSupplier.SupplierID && (
                <div className="row h-100">
                    <div className="col-md-12 p-0">
                        <div className="card card-custom position-absolute h-100 w-100">
                            <div className="card-body pt-0 overflow-auto">
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={value => setActiveTab(value)}
                                    id="controlled-tab"
                                    className={classes.tabHeader}
                                >
                                    {showAdminTabs && (
                                        <Tab
                                            eventKey="details"
                                            title={<FormattedMessage id="TABS.DETAILS" />}
                                        >
                                            <SupplierDetails
                                                selectedSupplier={selectedSupplier}
                                                allSuppliers={suppliersData.suppliers}
                                                submitSupplierCopyItem={saveCopyItem}
                                                submitSupplier={saveSupplier}
                                                submitCertificateDoc={saveCertificateDoc}
                                                updateSuppliers={updateSuppliers}
                                                saveImage={saveImage}
                                                isUserOwner={isUserOwner}
                                                settingsFreezeSupplier={settingsFreezeSupplierSave}
                                                driversList={driversList}
                                            />
                                        </Tab>
                                    )}
                                    {showAdminTabs && (
                                        <Tab
                                            eventKey="options"
                                            title={<FormattedMessage id="TABS.OPTIONS" />}
                                        >
                                            {
                                                activeTab === 'options' && (
                                                    <OptionGroupPanel
                                                        selectedSupplierID={selectedSupplier.SupplierID}
                                                        isUserAdmin={isUserAdmin}
                                                        isLoading={optionGroupsData.isLoading}
                                                        optionGroupsData={optionGroupsData}
                                                        setOptionGroupsData={setOptionGroupsData}
                                                        suppliers={suppliersData.suppliers}
                                                    />
                                                )
                                            }

                                        </Tab>
                                    )}
                                    {showAdminTabs && (
                                        <Tab
                                            eventKey="items"
                                            title={<FormattedMessage id="TABS.ITEMS" />}
                                        >
                                            {
                                                activeTab === 'items' && (
                                                    <>
                                                        {
                                                            isUserAdmin && (
                                                                <SupplierImportDetails
                                                                    selectedSupplier={selectedSupplier}
                                                                    allSuppliers={suppliersData.suppliers}
                                                                    submitSupplierCopyItem={saveCopyItem}
                                                                    submitSupplier={saveSupplier}
                                                                    submitCertificateDoc={saveCertificateDoc}
                                                                    updateSuppliers={updateSuppliers}
                                                                    saveImage={saveImage}
                                                                    isUserAdmin={isUserAdmin}
                                                                    settingsFreezeSupplier={settingsFreezeSupplierSave}
                                                                    statusLoading={(status) => {
                                                                        setProcessLoading(status)
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                        {
                                                            !processLoading && (
                                                                <ItemPanel
                                                                    selectedSupplierID={selectedSupplier.SupplierID}
                                                                    selectedSupplierName={selectedSupplier.Name}
                                                                    optionGroups={optionGroupsData.optionGroups.filter(
                                                                        optionGroup =>
                                                                            optionGroup.Versions.find(
                                                                                version => version.Status === 3
                                                                            )
                                                                    )}
                                                                    isLoading={optionGroupsData.isLoading}
                                                                    isUserAdmin={isUserAdmin}
                                                                    loadOptionGroups={loadOptionGroups}
                                                                />
                                                            )
                                                        }
                                                    </>
                                                )
                                            }

                                        </Tab>
                                    )}
                                    <Tab
                                        eventKey="meals"
                                        title={<FormattedMessage id="TABS.MEALS" />}
                                    >
                                        <MealsPanel
                                            isActiveTab={activeTab === "meals"}
                                            selectedSupplierID={selectedSupplier.SupplierID}
                                            isUserAdmin={isUserAdmin}
                                            isUSFormatDate={isUSFormatDate}
                                        />
                                    </Tab>
                                    <Tab
                                        eventKey="orders"
                                        title={<FormattedMessage id="TABS.ORDERS" />}
                                    >
                                        <OrdersPanel
                                            isActiveTab={activeTab === "orders"}
                                            selectedSupplierID={selectedSupplier.SupplierID}
                                            isUserAdmin={isUserAdmin}
                                            isUSFormatDate={isUSFormatDate}
                                        />
                                    </Tab>
                                    {isUserAdmin && (
                                        <Tab
                                            eventKey="specialDates"
                                            title={<FormattedMessage id="TABS.SPECIAL_DATES" />}
                                        >
                                            <SpecialDatesPanel
                                                selectedSupplier={selectedSupplier}
                                                updateSupplier={supplier =>
                                                    setSelectedSupplier({ data: supplier })
                                                }
                                            />
                                        </Tab>
                                    )}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default injectIntl(withRouter(SuppliersEditPage))
