import axios from "axios"

export function getSuppliers(cancelToken, state, all) {
  return axios.get(`/Suppliers?state=${all ? '' : (state || '')}`, {
    cancelToken
  })
}
export function getWorkers(cancelToken) {
  return axios.get(`/OnFleet/GetWorkers`, {
    cancelToken
  })
}
export function getSupplier(cancelToken, supplierID) {
  return axios.get(`/Suppliers/${supplierID}`, {
    cancelToken
  })
}
export function createSupplier(data) {
  return axios.post(`/Supplier`, data)
}
export function updateSupplier(data, supplierID) {
  return axios.patch(`/Suppliers/${supplierID}`, data)
}
export function settingsFreezeSupplier(data, supplierID) {
  return axios.patch(`/Suppliers/${supplierID}/Settings/Freeze`, data)
}

export function saveCopyItemSupplier(data, supplierID) {
  return axios.post(`/Suppliers/${supplierID}/Items/Copy`, data)
}

export function supplierSettings(data, supplierID) {
  return axios.patch(`/Suppliers/${supplierID}/Settings`, data)
}


export function getLocations(supplierID, isIncludedMealInfo, cancelToken) {
  return axios.get("/Suppliers/GetLocations", {
    params: {
      supplierID,
      isIncludedMealInfo: isIncludedMealInfo || null
    },
    cancelToken
  })
}

export function setCertificateDoc(supplierID, data) {
  return axios.post(`Supplier/${supplierID}/Document`, data)
}

export function saveSupplierLogo(data, supplierID) {
  return axios.post(`/Supplier/Logo/${supplierID}`, data)
}
