/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import CreateLocationButton from "../../Common/CreateNewButton"
import LocationForm from "./LocationForm"
import LocationList from "../ElementList"
import {
    getWorkers,
    updateLocation,
    createLocation,
    deleteLocation,
    getCustomerLocations
} from "../_axios/customerCrud.js"
import CreateLocationDialog from "./CreateLocationDialog"
import { FormattedMessage, injectIntl } from "react-intl"
import ConfirmationDialog from "../../Common/ConfirmationDialog"
import { getSuppliers } from "../../Suppliers/_axios/supplierCrud"
import { getTags } from "../../Tags/_axios/tagCrud"
import { updateMeal, getMeals } from "../_axios/customerCrud"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../../redux/snackbar/snackbarHandlers"
import DishLoader from "../../Common/DishLoader"

function LocationPanel({ intl, selectedCustomer, updateCustomer }) {
    const dispatch = useDispatch()

    const [showCreateLocationPanel, setShowCreateLocationPanel] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState({ LocationID: "" })
    const [driversList, setDriversList] = useState([])
    const [supplierData, setSupplierData] = useState({
        suppliers: [],
        isLoading: true
    })
    const [locationsData, setLocationsData] = useState({
        locations: [],
        isLoading: true
    })
    const [tagsData, setTagsData] = useState({
        tags: [],
        isLoading: true
    })
    const [deleteDialogData, setDeleteDialogData] = useState({
        show: false,
        isSubmitting: false
    })
    const [mealsData, setMealsData] = useState({
        meals: [],
        isLoading: false
    })
    function formatMealsInfo(mealsInfo) {
        return mealsInfo.map(mealInfo => ({
            ...mealInfo,
            MealItems: mealInfo.MealItems.map(mealItem => ({
                ...mealItem,
                From:
                    mealItem.From && mealItem.From !== "0001-01-01T00:00:00"
                        ? new Date(mealItem.From)
                        : null,
                To:
                    mealItem.To && mealItem.To !== "0001-01-01T00:00:00"
                        ? new Date(mealItem.To)
                        : null
            }))
        }))
    }

    function selectLocation(Name) {
        if (selectedLocation.Name !== Name) {
            const location = locationsData.locations.find(
                location => location.Name === Name
            )
            setSelectedLocation({
                ...location,
                Suppliers: location.Suppliers.slice().map(item => {
                    return { ...item };
                }),
                MealsInfo: formatMealsInfo(location.MealsInfo)
            })
        }
    }

    function saveLocation(submittedLocation) {
        return updateLocation(submittedLocation, submittedLocation.LocationID)
    }

    function removeLocation() {
        const changedCustomer = { ...selectedCustomer }
        changedCustomer.Locations = changedCustomer.Locations.filter(
            location => location.LocationID !== selectedLocation.LocationID
        )
        updateCustomer(changedCustomer)
        setSelectedLocation({ LocationID: "" })

        setLocationsData(locationData => ({
            ...locationData,
            locations: locationData.locations.filter(location => location.LocationID !== selectedLocation.LocationID),
            isLoading: false
        }));
    }

    function updateLocations(submittedLocation) {
        const changedCustomer = { ...selectedCustomer }
        const locations = changedCustomer.Locations
        const changedLocation = locationsData.locations.find(
            item => item.LocationID === submittedLocation.LocationID
        )
        if (changedLocation) {
            changedLocation.Name = submittedLocation.Name
            changedLocation.SupplierDisplayName =
                submittedLocation.SupplierDisplayName
            changedLocation.Address = submittedLocation.Address
            changedLocation.OrderType = submittedLocation.OrderType
            changedLocation.AdvanceOrderType = submittedLocation.AdvanceOrderType
            changedLocation.Suppliers = submittedLocation.Suppliers
            changedLocation.MealsInfo = submittedLocation.MealsInfo
            changedLocation.ReportEmails = submittedLocation.ReportEmails
            changedLocation.ReportDeliveryTime = submittedLocation.ReportDeliveryTime
            changedLocation.DriverId = submittedLocation.DriverId
            updateCustomer(changedCustomer)
            setSelectedLocation({ ...changedLocation })
            setSelectedLocation({ LocationID: "" })
        } else {

            const newLocation = {
                ...submittedLocation,
                OrderType: 1,
                NumberOfDaysToOrder: 0,
                MaxOrderDate: "",
                SupplierDisplayName: "",
                MealsInfo: [],
                Suppliers: [],
                // PriceRanges: []
            }
            locations.push(newLocation)
            changedCustomer.Locations.push(newLocation);
            updateCustomer(changedCustomer)
            setShowCreateLocationPanel(false)
            setSelectedLocation(newLocation)
            setSelectedLocation({ LocationID: "" })
            setLocationsData({ locations: locationsData.locations.slice().concat(newLocation), isLoading: false })
        }
    }

    function fetchLocations(cancelToken) {
        getCustomerLocations(selectedCustomer.CustomerID, cancelToken.token)
            .then(({ data }) => {
                setLocationsData({ locations: data, isLoading: false })
                setSelectedLocation({ LocationID: "" })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
                    })
                )
            )
    }

    function fetchSuppliers(cancelToken) {
        getSuppliers(cancelToken.token, undefined, true)
            .then(({ data }) => {
                setSupplierData({ suppliers: data, isLoading: false })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    function fetchTags(cancelToken) {
        setTagsData({ ...tagsData, isLoading: true })
        getTags(cancelToken.token)
            .then(({ data }) => {
                setTagsData({
                    tags: data,
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_TAGS"
                    })
                )
            )
    }

    function fetchMeals(cancelToken) {
        setMealsData({ meals: [], isLoading: true })
        getMeals(selectedLocation.LocationID, cancelToken.token)
            .then(({ data }) => {
                setSelectedLocation({
                    ...selectedLocation,
                    MealsInfo: formatMealsInfo(data)
                })
                setMealsData({ meals: [], isLoading: false })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_MEAL_INFO"
                    })
                )
            )
    }

    function fetchDrivers(cancelToken) {
        setDriversList([])

        getWorkers(cancelToken.token)
            .then(({ data }) => {
                const formattedDrivers = data.map((driver, index) => ({
                    name: driver.displayName || driver.name,
                    value: driver.id
                }));

                setDriversList(formattedDrivers);
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIER"
                    })
                )
            )
    }

    function saveMeal(submittedMeal) {
        return updateMeal(selectedLocation.LocationID, submittedMeal)
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchSuppliers(cancelToken)
        fetchTags(cancelToken)
        return () => cancelToken.cancel()
    }, [])

    useEffect(() => {
        if (selectedLocation.LocationID) {
            const cancelToken = axios.CancelToken.source()
            fetchMeals(cancelToken)
            return () => cancelToken.cancel()
        }
    }, [selectedLocation.LocationID])

    useEffect(() => {
        setSelectedLocation({ LocationID: "" })
        const cancelToken = axios.CancelToken.source()
        fetchLocations(cancelToken)
        return () => cancelToken.cancel()
    }, [selectedCustomer.CustomerID])

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchDrivers(cancelToken)
    }, [])

    const handleNewLocationSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        const newLocation = {
            Name: values.name,
            CustomerID: selectedCustomer.CustomerID,
            City: values.city,
            Country: values.country,
            Street: values.street,
            Address: values.address,
            GooglePlaceId: values.googlePlaceId,
            GeoPoint: {
                lat: values.geoPoint.lat,
                lon: values.geoPoint.lon,
            }
        }
        createLocation(newLocation)
          .then(({ data }) => {
            setSubmitting(false)
            updateLocations({ ...newLocation, LocationID: data.LocationID })
            resetForm()
          })
          .catch(error => {
            setSubmitting(false)
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_CREATE_LOCATION"
              })
            )
          })
    }

    const handleAcceptDeleteLocation = () => {
        setDeleteDialogData({ ...deleteDialogData, isSubmitting: true })

        deleteLocation(selectedLocation.LocationID)
            .then(() => {
                setDeleteDialogData({ show: false, isSubmitting: false })
                removeLocation()
            })
            .catch(error => {
                setDeleteDialogData({ ...deleteDialogData, isSubmitting: false })
                error.response.data &&
                    error.response.data.Message === "Location is used for users"
                    ? handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: "API.ERROR.LOCATION_IS_USED"
                        })
                    )
                    : handleApiError(
                        dispatch,
                        error,
                        intl.formatMessage({
                            id: "API.ERROR.FAILED_TO_DELETE_LOCATION"
                        })
                    )
            })
    }
    return (
        <>
            <ConfirmationDialog
                show={deleteDialogData.show}
                onSubmit={handleAcceptDeleteLocation}
                onClose={() => setDeleteDialogData({ show: false })}
                isSubmitting={deleteDialogData.isSubmitting}
                dialogTitle={<FormattedMessage id="DELETE_LOCATION_DIALOG.TITLE" />}
                contentText={
                    intl.formatMessage({
                        id: "DELETE_LOCATION_DIALOG.TEXT"
                    }) +
                    " " +
                    selectedLocation.Name +
                    "?"
                }
            />
            <CreateLocationButton onClick={() => setShowCreateLocationPanel(true)} />
            <CreateLocationDialog
                show={showCreateLocationPanel}
                title={<FormattedMessage id="CREATE_LOCATION_FORM.TITLE" />}
                closeDialog={() => setShowCreateLocationPanel(false)}
                handleSubmit={handleNewLocationSubmit}
                checkIfNameUnique={value => {
                    const location = locationsData.locations.find(
                        location => location.Name === value
                    )
                    return location === undefined
                }}
            />
            <div className="row">

                <div className="col-2">
                    {
                        locationsData.isLoading && <DishLoader center />
                    }
                    {
                        !locationsData.isLoading && <LocationList
                            rows={locationsData.locations}
                            selected={selectedLocation.Name}
                            setSelected={selectLocation}
                        />
                    }

                </div>
                <div className="col-10">
                    {selectedLocation.LocationID &&
                        (selectedLocation.MealsInfo &&
                            selectedLocation.MealsInfo.length !== 0 &&
                            !supplierData.isLoading &&
                            !tagsData.isLoading ? (
                            <LocationForm
                                selectedLocation={selectedLocation}
                                setSelectedLocation={setSelectedLocation}
                                locations={locationsData.locations}
                                allSuppliers={supplierData.suppliers}
                                // allPriceRanges={selectedLocation.PriceRanges}
                                allTags={tagsData.tags}
                                loading={mealsData.isLoading}
                                onSubmit={saveLocation}
                                saveMeal={saveMeal}
                                updateLocations={updateLocations}
                                handleDelete={() => setDeleteDialogData({ show: true })}
                                saveLocation={saveLocation}
                                driversList={driversList}
                            />
                        ) : (
                            <DishLoader />
                        ))}
                </div>
            </div>
        </>
    )
}
export default injectIntl(LocationPanel)
